import React, { useState } from "react";
import Sidenav from "../SideNav/Sidenav";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import PendingRequest from "./PendingRequest";
import CompletedRequest from "./CompletedRequest";
import RejectedRequest from "./RejectedRequest";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <h2>{children}</h2>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Request = () => {
  const [value, setValue] =useState(0);

  const handleChangetable = (event, newValue) => {
    setValue(newValue);
  };
  
  return (
    <div className="container-fluid backcolor ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 container-fluid bgcolor">
          <div className="pl-4 pt-3 pb-3 pr-3">
            <div className="requestbackground ">
              <h2>Request List</h2>
              <div style={{ width: "100%" }}>
                <div style={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    value={value}
                    onChange={handleChangetable}
                    aria-label="basic tabs example"
                  >
                    <Tab label="New Request" {...a11yProps(0)} />
                    <Tab label="Completed Request" {...a11yProps(1)} />
                    <Tab label="Rejected Request" {...a11yProps(2)} />
                  </Tabs>
                </div>
                <TabPanel value={value} index={0}>
                  <PendingRequest />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <CompletedRequest />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <RejectedRequest />
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Request;
