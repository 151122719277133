import React, { useEffect, useState } from "react";
import SettingsSuggestOutlinedIcon from "@mui/icons-material/SettingsSuggestOutlined";
import PersonIcon from "@mui/icons-material/Person";
import ViewInArRoundedIcon from "@mui/icons-material/ViewInArRounded";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import dayjs from "dayjs";
import axios from "axios";
const Usercardcomponant = () => {
  // const [city, setCity] = React.useState('');
  const [noService, setNoService] = useState();
  const [noProduct, setNoProduct] = useState();
  const [noUser, setNoUser] = useState();
  const [datevalue, setDateValue] = React.useState([
    dayjs().startOf("month"),
    dayjs(),
  ]);

  // const handleChange = (event) => {
  //   setCity(event.target.value);
  // };

  useEffect(() => {
    sendDateToBackend();
  }, []);
  const sendDateToBackend = () => {
    if (datevalue[0] && datevalue[1]) {
      const data = {
        startDate: datevalue[0].format("YYYY-MM-DD"),
        endDate: datevalue[1].format("YYYY-MM-DD"),
      };
      axios
        .post(process.env.REACT_APP_API_URL + "dashboard", data)
        .then((resp) => {
          setNoService(resp.data.NumberofService);
          setNoProduct(resp.data.NumberofProduct);
          setNoUser(resp.data.NumberofUser);
        })
        .catch((error) => {
          // Handle errors if any
          console.error("Error sending data:", error);
        });
    }
  };
  return (
    <div className="container-fluid ">
      <div className="container-fluid  toper_container">
        <div className="row mt-3 mb-4 ">
          <div className="col-md-6"></div>
          <div className="col-md-6 ">
            <div
              style={{
                display: "flex",
                alignItems: "end",
                justifyContent: "end",
              }}
            >
              <div className="pd-0">
                {/* <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={top100Films}
      style={{width:"200px",marginRight:"10px"}}
      renderInput={(params) => <TextField {...params} label="City" />}
    /> */}
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["DateRangePicker"]}>
                    <DateRangePicker
                      value={datevalue}
                      onChange={(newValue) => {
                        setDateValue(newValue);
                        sendDateToBackend(); // Call the function when date range changes
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-4 p-1">
          <div className="card border-0 shadow p-1 ">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h5>Services</h5>
                    <h3 className="success">{noService}</h3>
                  </div>
                  <div className="align-self-center card-icon-1">
                    <SettingsSuggestOutlinedIcon className="setting_icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-md-4 p-1">
          <div className="card border-0 shadow p-1">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h5>Products</h5>
                    <h3 className="success">{noProduct}</h3>
                  </div>
                  <div className="align-self-center card-icon-2">
                    <ViewInArRoundedIcon className="view_icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 p-1">
          <div className="card border-0  shadow p-1 ">
            <div className="card-content">
              <div className="card-body">
                <div className="media d-flex">
                  <div className="media-body text-left">
                    <h5>Users</h5>
                    <h3 className="success">{noUser}</h3>
                  </div>
                  <div className="align-self-center card-icon-3">
                    <PersonIcon className="user_icon" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Usercardcomponant;

// const top100Films = [
//   { label: 'Banglore' },
//   { label: 'Tumkur' },
//   { label: 'Mysore' },
//   { label: 'Shivamogga'},
//   { label: 'Sagar' },
// ]
