import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css'; 
import { BrowserRouter as Router, Routes, Route} from "react-router-dom";
import PrivateRoute from './pages/PrivateRoute';
import Header from './pages/Header/Header';
import Login from './pages/Login/Login';
import Dashboard from './pages/Dashboard/Dashboard';
import Review from './pages/Review/Reviews';
import Blog from './pages/Blogs/Blog';
import User from './pages/User/User';
import RequestEdit from './pages/Request/RequestEdit';
import Discussions from './pages/Discussion/Discussions';
import Request from './pages/Request/Request';
import ProductList from './pages/Products list/Productlist';
import ServiceList from './pages/Service list/ServiceList';
// import { useEffect,useState } from 'react';


function App() {
    console.log(localStorage.getItem("userName"))
  return (
    <div className="App">
     
     <Router>
     {localStorage.getItem("userName")!=="" && localStorage.getItem("userName")!==null && localStorage.getItem("userName")!==undefined ?<Header/>:<></>} 
      
     <Routes>
           <Route path="/dashboard" element={<PrivateRoute><Dashboard/></PrivateRoute>} />
           <Route path="/review" element={<PrivateRoute><Review/></PrivateRoute>} />
           <Route path="/blog" element={<PrivateRoute><Blog/></PrivateRoute>} />
           <Route path="/User" element={<PrivateRoute><User/></PrivateRoute>} />
           <Route path="/request-edit" element={<PrivateRoute><RequestEdit/></PrivateRoute>} />
           <Route path="/request" element={<PrivateRoute><Request/></PrivateRoute>} />
           <Route path="/discussions" element={<PrivateRoute><Discussions/></PrivateRoute>} />
           <Route path='/productlist' element={<PrivateRoute><ProductList/></PrivateRoute>}/>
           <Route path='/servicelist' element={<PrivateRoute><ServiceList/></PrivateRoute>}/>
            <Route path="/" element={<Login/>}/>
      
              
         </Routes>
         
        
        </Router>
        <NotificationContainer/>
    </div>
    
  );
}

export default App;