import React from "react";
import { useEffect, useState } from "react";
import Sidenav from "../SideNav/Sidenav";
import MUIDataTable from "mui-datatables";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import {
  TextField,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  Modal,
  Button,
  IconButton,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { NotificationManager } from "react-notifications";
import Axios from "../../axios";
const ServiceList = () => {
  const [service, setService] = useState([]);
  const [open, setOpen] =useState(false);
  const [allcity, setAllCity] = useState([]);
  const [allcategory, setAllCategory] = useState([]);
  const [servicename, setServiceName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [image, setImage] = useState("");
  //----------------------------open function for edit option-------------------------------------
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
   
  };
  //-------------------------------Function to close the model--------------------------------------
  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // -----------------------------to get the Service Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getservice")
      .then((resp) => {
        setService(resp.data.servicedetails);
        setCount(resp.data.numberofRecords);
      })
      .catch((error) => console.log(error));
    findArea();
    findCategory();
  }, []);

  //----------------------------------function to target the value by name------------------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "service") {
      setServiceName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "area") {
      setArea(value);
    } else if (name === "city") {
      setCity(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "url") {
      setUrl(value);
    // } else if (name === "category") {
    //   setCategory(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };
  //------------------------------function for findone service using id-------------------------------------- 
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findoneservice", data) //storing data 1 in a axios
      .then((resp) => {
        setModelTitle(resp?.data?.servicedetails.title);
        setServiceName(resp?.data?.servicedetails.title);
        setEmail(resp?.data?.servicedetails.mail);
        setPhone(resp?.data?.servicedetails.phone_number);
        setArea(resp?.data?.servicedetails?.areaName);
        if (resp?.data?.servicedetails?.area_ref) {
          setCity(resp?.data?.servicedetails?.area_ref);
        } else {
          setCity(null);
        }
        setAddress(resp?.data?.servicedetails.address);
        setUrl(resp?.data?.servicedetails.website);
        if (resp?.data?.servicedetails?.category_ref) {
          setCategory(resp?.data?.servicedetails?.category_ref);
        } else {
          setCategory(""); 
        }
        setStatus(resp?.data?.servicedetails.status);
      })
      .catch((error) => console.log(error));
  };

  const handleSubmit = () => {
    const categoryRefValue = category ? category._id : null;
     const cityRefValue =city ? city._id : null;
       const formData = new FormData();
       formData.append("id", updateId);
        formData.append("title", servicename);
        formData.append("mail", email);
        formData.append("phone_number", phone);
        formData.append("areaName", area);
        formData.append("area_ref",cityRefValue)
        formData.append("address", address);
        formData.append("website", url);
        formData.append("category_ref", categoryRefValue);
         formData.append("images", image);
         formData.append("status",status);
    Axios
      .post(process.env.REACT_APP_API_URL + "updateservice", formData) //storing data1
      .then((resp) => {
        setService(resp.data.data);
        NotificationManager.success(resp.data.msg);
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };

  //------------------------------------Function to set the image ------------------------------
    const handleImageChange = (event) => {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);
    };
    const imageName = image ? image.name : "";//it store the image name 
//-------------------------------------Function to fetch all the area on load----------------
  const findArea = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getallservicesarea")
      .then((resp) => {
        setAllCity(resp.data.areadetails);
      })
      .catch((error) => console.log(error));
  };
//-------------------------------Function to fetch all category on load-----------------------------------
  const findCategory = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getallcategory")
      .then((resp) => {
        setAllCategory(resp.data.categorydetails);
      })
      .catch((error) => console.log(error));
  };
//-------------------------------function to set the category-----------------------------------
  const handleChangecategory = (event, value) => {
    setCategory(value === "" ? null : value);
  };
  //-------------- Columns for service Table Start here *service is the array for the data-------------
  const columns = [
    {
      name: "title",
      label: "Service Name",
    },
    {
      name: "Category",
      label: "Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const categoryRef = service[dataIndex].category_ref;
          return <div>{categoryRef ? categoryRef.Category : ""}</div>;
        },
      },
    },
    {
      name: "phone_number",
      label: "Mobile No",
    },
    {
      name: "city",
      label: "City",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const categoryRef = service[dataIndex].area_ref;
          return <div>{categoryRef ? categoryRef.city_name : ""}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              {service[dataIndex].status !== "Active" ? (
                <p className="mt-3" style={{ color: "red" }}>
                  Inactive
                </p>
              ) : (
                <p className="mt-3" style={{ color: "green" }}>
                  Active
                </p>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="usericon">
              <IconButton
                className="bordernone"
                aria-label="edit"
                onClick={() => handleOpen(service[dataIndex]._id)}
              >
                <EditNoteOutlinedIcon size="small" />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  // ------------------------Columns End here---------------------------------------------
   const handlePageChange = (page) => {
     const data = {
       page: page,
     };
     axios
       .post(process.env.REACT_APP_API_URL + "getservice", data)
       .then((resp) => {
         setService(resp.data.servicedetails);
         setCount(resp.data.numberofRecords);
         setPage(page);
       })
       .catch((error) => console.log(error));
   };

   const options = {
     responsive: "standard",
     selectableRows: "none",
     filters: false,
     rowsPerPage: 10,
     serverSide: true,
     //count, // Use total number of items
     count: count, // Unknown number of items
     page,
     onTableChange: (action, tableState) => {
       if (action === "changePage") {
         console.log("to page", tableState.page);
         handlePageChange(tableState.page);
       }
     },
   };
 
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 bgcolor">
          <div className="request_padding">
            <MUIDataTable
              className=""
              title={"Service List"}
              data={service}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
      <div>
        {/* --------------------Modal for edit service Details------------------------------- */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal_box ">
            <div className="requestheader mb-4">
              <CreateNewFolderOutlinedIcon className="me-1" />
              <p className="p-0 m-0 fw-600"> {modelTitle}</p>
            </div>
            <TextField
              type="text"
              className="form-control mb-4"
              value={servicename}
              name="service"
              onChange={handleChange}
              placeholder="Enter the Service Name"
              label="Product/Service Name*"
            />
            <TextField
              type="text"
              className="form-control mb-4"
              value={email}
              name="email"
              onChange={handleChange}
              placeholder="Enter the  Email"
              label="Email"
            />
            <TextField
              type="text"
              value={phone}
              name="phone"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the phone number"
              label="Phone Number"
            />
            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              options={allcity}
              value={city ? city : null}
              onChange={(event, value) => {
                setCity(value);
              }}
              getOptionLabel={(allcity) =>
                allcity ? `${allcity.areaName}, ${allcity.city_name}` : ""
              }
              renderInput={(params) => <TextField {...params} label="City" />}
            />
            <TextField
              fullWidth
              name="address"
              value={address}
              onChange={handleChange}
              placeholder="Enter address"
              label="Address"
              className="mb-4"
              multiline
            />
            <TextField
              type="text"
              value={url}
              name="url"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the website URL"
              label="Website URL"
            />
            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              name="category"
              options={allcategory}
              onChange={handleChangecategory}
              getOptionLabel={(opt) =>
                opt && opt?.Category !== null ? opt?.Category : ""
              }
              value={
                allcategory.find((options) => options._id === category?._id) ||
                null
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                fullWidth
                style={{ height: "60px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                name="status"
                label="Status"
                placeholder="Select the Status"
                onChange={handleChange}
                className="form-control mb-4"
              >
                <MenuItem value="Active"> Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" component="label" className="mb-4">
                Upload File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                {imageName}
              </p>
            </div>
            <div className="txt-center">
              <Button
                variant="contained"
                className="mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        {/* --------------------Modal end here------------------------------- */}
      </div>
    </div>
  );
};

export default ServiceList;
