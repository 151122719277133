import React from "react";
import Chart from "chart.js/auto";
import { Pie } from "react-chartjs-2";
const labels = ["Services", "Users", "Products"];
const data = {
  labels: labels,
  datasets: [
    {
      label: "Data",
      backgroundColor: [
        'LightBlue',
        'red',
        'orange',
      ],
      borderColor: [
        'LightBlue',
        'red',
        'orange',
      ],
      data: [40, 20, 40],
    },
  ],
};
const PieChart = () => {
  return (
    <div>
      <Pie data={data} />
    </div>
  );
};
export default PieChart;