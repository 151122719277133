import React, { useEffect, useState } from "react";
import Sidenav from "../SideNav/Sidenav";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {
  Modal,
  TextField,
  Autocomplete,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Button,
  IconButton,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import Axios from "../../axios";
import moment from "moment/moment";
import { NotificationManager } from "react-notifications";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const Blog = () => {
  //------------------------Variable Decleartion------------------------------
  const [blogs, setBlogs] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [deleteopen, setDeleteOpen] =useState(false);
  const [deleteId, setDeleteId] = useState("");
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescripion] = useState("");
  const [shortDescription, setShortDiscription] = useState("");
  const [updateId, setUpdateId] = useState("");
  const [allcategory, setAllCategory] = useState([]);
  const [status, setStatus] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage,setTotalPage]=useState(0)
  const [image,setImage]=useState("");

  //------------------------------- open function for edit Modal------------------------------
  const handleOpen = (id) => {
    setOpen(true);
    handlefindone(id);
    setUpdateId(id);
  };

  useEffect(() => {
    // -----------------------------to get the Blog Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getblogs")
      .then((resp) => {
        setBlogs(resp.data.blogdetails);
        setTotalPage(resp.data.page);
      })
      .catch((error) => console.log(error));
    //-----------------------------to get the category details on load------------------------
    findBlogCategory();
    
  }, []);

  //-----------------------------click on Delete icon it reads the ID of Particular Data---------------
  const handleClickOpen = (id) => {
    setDeleteOpen(true);
    setDeleteId(id); // setting the delete ID
  };

  //----------------------------------delete function for Blog-----------------------------
  const handleDelete = () => {
    const data = {
      _id: deleteId,
      page: page, // assigning deleteid To object ID and storing it to data
    };
    Axios
      .post(process.env.REACT_APP_API_URL + "deleteblog", data)
      .then((resp) => {
        setBlogs(resp.data.data);
        NotificationManager.success(resp.data.msg);
        setDeleteOpen(false);
      })
      .catch((error) => console.log(error));
  };
  //----------------------------------function for findone Blog using id---------------------
  const handlefindone = (id) => {
   
    const data = {
      id: id,
  
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findoneblog", data)
      .then((resp) => {
        setModelTitle(resp?.data?.blogdetails.title);
        setTitle(resp?.data?.blogdetails.title);
        setCategory(resp?.data?.blogdetails?.category_ref);
        setDescripion(resp?.data?.blogdetails.content);
        setStatus(resp?.data?.blogdetails.status);
        if (resp?.data?.blogdetails?.short_description)
        {
          setShortDiscription(resp?.data?.blogdetails?.short_description);
        }
        else
        {
          setShortDiscription("")
        }
        setImage(resp?.data?.blogdetails.thumbnail);
      })
      .catch((error) => console.log(error));
  };
  //------------------------------close function for edit option-------------------------------
  const handleClose = () => {
    setDeleteOpen(false);
    setOpen(false);
    setTitle("")
    setCategory("")
    setDescripion("")
    setShortDiscription("")
    setStatus("")
  };
  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };
 const imageName = image ? image.name : "";
  //---------------------------Function to Submiting the data after the update-----------------------------
  const handleSubmit = () => {
    const categoryRefValue = category ? category._id : null;
    const formData = new FormData();    
    formData.append("id", updateId);
    formData.append("title",title)
    formData.append("category_ref", categoryRefValue);
    formData.append("page", page)
    formData.append("content", description)
    formData.append("status", status);
    formData.append("short_description", shortDescription);
    formData.append("thumbnail", image);
    Axios
      .post(process.env.REACT_APP_API_URL + "blogupdate", formData) 
      .then((resp) => {
        setBlogs(resp.data.data);
        NotificationManager.success(resp.data.msg);
         setTitle("");
         setCategory("");
         setDescripion("");
         setShortDiscription("");
         setStatus("");
        
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };
  //-------------------------------Function to find all the category and set the Category data------------
  const findBlogCategory = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "blogcategory")
      .then((resp) => {
        setAllCategory(resp.data.blogcategorydetails);
      })
      .catch((error) => console.log(error));
  };

  //--------------------------Change funtion to setStates targeting name----------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "title") {
      setTitle(value);
    }  else if (name === "description") {
      setDescripion(value);
    } else if (name === "short_description") {
      setShortDiscription(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };
  //-------------------------------Function to set catagory----------------------
  const handleChangecategory = (event, value) => {
    setCategory(value === "" ? null : value);
  };
  //------------------------set the page value------------------------------------
  const handlePageChange = (event, value) => {
    setPage(value);
  handleCard(value)
  };

  
  const handleCard = (page) => {
    console.log(page)
    const data = {
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "getblogs", data)
      .then((resp) => {
        setBlogs(resp.data.blogdetails);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 bgcolor p-1 ">
          <div className="blog_padding">
            <div className="mt-0 ml-2">
              <h1>Blog List</h1>
            </div>
            <div className="card_flex ">
              {blogs.map((card, index) => (
                <div
                  key={index}
                  className="card blogcard border-0 shadow m-2 p-1 "
                >
                  <div className="image-container">
                    {card.thumbnail && (
                      <>
                        <img
                          src={card.thumbnail}
                          alt="img"
                          className="img_radius"
                        />
                        <div className="image-text-overlay p-0 m-0 top-left-overlay text-center ">
                          <p className="image-description mt-1 mb-1 pl-2 pr-2">
                            {moment(card?.createdAt).format("MMM Do YY")}
                          </p>
                        </div>
                        <div className="image-text-overlay p-0 m-0 bottom-right-overlay text-center">
                          <p className="image-description mt-1 mb-1 pl-2 pr-2">
                            {card.category_ref.name}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                  <div className="card-body cardbodypadding">
                    <h4 className="card-title limitedline">{card.title}</h4>
                    <p className="limitedline_shortdiscription ">
                      {card.short_description}
                    </p>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <p
                        className="blog_condition"
                        style={{
                          backgroundColor:
                            card.status === "Pending"
                              ? "yellow"
                              : card.status === "Rejected"
                              ? "red"
                              : "green",
                        }}
                      >
                        {card.status}
                      </p>
                      <div>
                        <IconButton
                          className="bordernone"
                          aria-label="edit"
                          onClick={() => handleOpen(card._id)}
                        >
                          <EditNoteOutlinedIcon className="icon_size_below" />
                        </IconButton>

                        <IconButton
                          className="bordernone"
                          aria-label="delete"
                          onClick={() => handleClickOpen(card._id)}
                        >
                          <DeleteOutlineIcon className="icon_size_below" />
                        </IconButton>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  defaultPage={1}
                  variant="outlined"
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
            {/* </div> */}
          </div>
        </div>
        {/* --------------------Modal for delete Blog------------------------------- */}
        <Modal
          open={deleteopen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="delete_modal_box pt-0 p-2">
            <div className="aligincenter">
              <p className="p-1 ml-2 m-0">Delete Blog</p>
              <ClearIcon className="" fontSize="small" onClick={handleClose} />
            </div>
            <Divider className="mb-2"></Divider>
            <p className="ml-4 font_size_for_p">Are you sure want to delete?</p>
            <div className="p-0 deletebox">
              <Button
                variant="contained"
                className="mr-2 delete_button_size"
                onClick={handleClose}
              >
                No
              </Button>
              <Button
                variant="contained"
                className="delete_button_size"
                onClick={handleDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        {/* --------------------Modal End Here------------------------------- */}
        <div>
          {/* --------------------Modal for edit Blog Details------------------------------- */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="modal_box ">
              <div className="requestheader mb-4">
                <CreateNewFolderOutlinedIcon className="me-1" />
                <p className="p-0 m-0 fw-600"> {modelTitle}</p>
              </div>
              <TextField
                type="text"
                className="form-control mb-4"
                value={title}
                name="title"
                onChange={handleChange}
                placeholder="Enter title"
                label="Title"
              />
              <Autocomplete
                fullWidth
                freeSolo
                id="combo-box-demo"
                className=" mb-4"
                name="category"
                options={allcategory}
                onChange={handleChangecategory}
                getOptionLabel={(opt) =>
                  opt && opt?.name !== null ? opt?.name : ""
                }
                value={
                  allcategory.find(
                    (options) => options._id === category?._id
                  ) || null
                }
                renderInput={(params) => (
                  <TextField {...params} label="Category" />
                )}
              />
              <TextField
                type="text"
                className="form-control mb-4"
                value={shortDescription}
                name="short_description"
                onChange={handleChange}
                placeholder="Enter Short Description"
                label=" Short Description"
              />
              <TextField
                fullWidth
                name="description"
                value={description}
                onChange={handleChange}
                placeholder="Enter Description"
                label="Description"
                className="mb-4"
                multiline
              />
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Status</InputLabel>
                <Select
                  style={{ height: "60px" }}
                  fullWidth
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={status}
                  name="status"
                  label="Status"
                  placeholder="Select the Status"
                  onChange={handleChange}
                  className="form-control mb-4"
                >
                  <MenuItem value="Pending"> Pending</MenuItem>
                  <MenuItem value="Rejected">Rejected</MenuItem>
                  <MenuItem value="Approved">Approved</MenuItem>
                </Select>
              </FormControl>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button variant="contained" component="label" className="mb-4">
                  Upload File
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                  {imageName}
                </p>
              </div>
              <div className="txt-center">
                <Button
                  variant="contained"
                  className="mr-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
          {/* --------------------Modal end here------------------------------- */}
        </div>
      </div>
    </div>
  );
};
export default Blog;
