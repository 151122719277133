import React, { useState } from 'react'
import Sidenav from '../SideNav/Sidenav'
import Button from "@mui/material/Button";
import { TextField, TextareaAutosize,InputLabel,MenuItem,FormControl,Select} from '@mui/material';
import CreateNewFolderOutlinedIcon from '@mui/icons-material/CreateNewFolderOutlined';

const RequestEdit = () => {
  const[city,setCity]=useState("");
  const [verification,setVerification]=useState("");
  const [category,setCategory]=useState("");
   const[formData,setFormData]=useState({
      service:"",
      email:"",
      url:"",
      remarks:"",
      phone:"",
      address:"",
   })
   const [submitted, setSubmitted] = useState(false);
   const handleChange = (e) => {
     setFormData((prev)=>{
      let helper = {...prev}
      helper[`${e.target.name}`]=e.target.value;
      setSubmitted(false)
      setCity(e.target.value);
      return helper;
     })
    };
 
    const handleChangeCity = (event) => {
      setCity(event.target.value);
    };  
    const handleChangeVerification = (event) => {
      setVerification(event.target.value);
    }; 
    const handleChangeCategory= (event) => {
      setCategory(event.target.value);
    }; 

const handleSubmit = (e) => {
      e.preventDefault();
      setSubmitted(true);
  };
  const handleCancel=( )=>{
   setFormData("");
  
  };
const show=()=>{
   return (
      <div
          className="success"
          style={{
              display: submitted ? '' : 'none',
          }}>
          <p>
            {formData.service}<br/>{formData.email}<br/>{city}<br/>{formData.url}<br/>{verification}<br/>{formData.remarks}<br/>{category}<br/>{formData.phone}<br/>{formData.address}<br/>
          </p>
      </div>
  );
   
  }
return (
          <div className='container-fluid '>
    <div className='row'>
    <div className='col-md-2 p-0' >
          <Sidenav />
        </div>
        <div className='col-md-10 bgcolor'>
    <div className="requestedcards">
    <div className='card shadow '>
    <div className="card-header requestheader">
    <CreateNewFolderOutlinedIcon/>
    <p className='m-0 p-1 '>Services/Products</p>
  </div>
  <div className='card-body'>
  <div className='col-md-10 container-fluid'>
        <div className='row'>
            <div className='col-md-6'>
               
        <TextField type="text" className="form-control"  value={formData.service} name="service" onChange={handleChange} placeholder='Enter the Service Name' label="Product/Service Name*"/>
        <p className="p"></p>     
        <TextField type="email" className="form-control" value={formData.email} name='email' onChange={handleChange} placeholder="Enter the Email Id" label="Email Address"/>
        <p className="p"></p>  


        <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">city</InputLabel>
       <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={city}
    label="city"
    onChange={handleChangeCity}
  >
    <MenuItem value={"Bangalore"}>Bangalore</MenuItem>
    <MenuItem value={"Mangalore"}>Mangalore</MenuItem>
    <MenuItem value={"Udupi"}>Udupi</MenuItem>
  </Select>
</FormControl>


            <p className="p"></p>
            <TextField type='text' value={formData.url} name="url" className="form-control" onChange={handleChange} placeholder='Enter the website URL' label="Website URL" />
            <p className="p"></p>
            <TextField type="text" value={formData.remarks} name="remarks" className='form-control' onChange={handleChange} placeholder='Enter Remarks' label='Remarks'/>
            <p className="p"></p>
            <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Verification</InputLabel>
       <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={verification}
    label="Verification"
    onChange={handleChangeVerification}
  >
    <MenuItem value={"approved"}>approved</MenuItem>
    <MenuItem value={"pendding"}>pendding</MenuItem>
    <MenuItem value={"rejected"}>rejected</MenuItem>
  </Select>
</FormControl>
            </div>
           <div className='col-md-6'>
           
           <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Category</InputLabel>
       <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    value={category}
    label="Verification"
    onChange={handleChangeCategory}
  >
    <MenuItem value={1}>1</MenuItem>
    <MenuItem value={2}>2</MenuItem>
    <MenuItem value={3}>3</MenuItem>
  </Select>
</FormControl>
            <p className="p"></p>
            <TextField type="text" className="form-control" value={formData.phone} name="phone" onChange={handleChange} placeholder='Enter the Mobile Number' label="Phone Number"/>
            <p className="p"></p>
            <TextareaAutosize name="address" value={formData.address} onChange={handleChange} class="form-control textarea" placeholder='Enter address' label="Address" />
            <p className="p"></p>
           <div className='row p-2 '>
           <div className='col-md-6 '>
           <Button className="upload-button" type="submit" >Upload</Button>
            </div>
              <div className='col-md-6'>
              <Button className="view" type="submit" >View</Button>
            </div>
            </div>
            <div className='row p-2'>
           <div className='col-md-6'>
           <Button className="upload-button" type="submit" >Upload</Button>
            </div>
              <div className='col-md-6'>
              <Button className="view" type="view" >View</Button>
            </div>
            </div>
            </div>
          </div> 
          <div className="row p-2">
               <div className='col-md-6 cancel'>
               <Button className="view" type="view" onClick={handleCancel}>Cancel</Button>
               </div> 
               <div className='col-md-6 submit'>
               <Button className="view" type="view" onClick={handleSubmit} >Submit</Button>
               </div>
               </div> 
               </div>
</div>  
<div  style={{ display: submitted ? '' : 'none' }}>
                  {show()}
                  </div> 
   
</div>
</div>
</div>
</div>
 </div>   
)
}
export default RequestEdit