import React, { useEffect, useState } from "react";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import NoteAltOutlinedIcon from '@mui/icons-material/NoteAltOutlined';
import axios from "axios";
import moment from "moment/moment";
import GroupIcon from "@mui/icons-material/Group";
const NotificationComponant = () => {
  const [notifications, setNotification] = useState([]);
  const [newservices, setNewServices] = useState([]);
  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + "topfivenotification")
      .then((resp) => {
        setNotification(resp.data.data);
      })
      .catch((error) => console.log(error));
    topservices();
  }, []);
  const topservices = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "topfiverequest")
      .then((resp) => {
        setNewServices(resp.data.data);
      })
      .catch((error) => console.log(error));
  };
  return (
    <div className="container-fluid  mt-3">
      <div className="row">
        <h3>Recent Activity</h3>
        <div className="col-md-6 p-1">
          <div className="card border-0 shadow p-1 card_size_list">
            <div className="card-header icon_cont">
              <NotificationsNoneOutlinedIcon className="notification_cont ml-2" />
              <p className="m-0 p-1">Notifications</p>
            </div>
            {notifications.map((array, index) => (
              <ul className="list-group list-group-flush" key={index}>
                <li className="list-group-item d-flex justify-content-between align-items-center icon_cont notification_text">
                  <div className="d-flex align-items-center">
                    <div className="bgcolor">
                      {array.source === "Blog" ? (
                        <AssignmentOutlinedIcon className="icon-style" />
                      ) : (
                        <NoteAltOutlinedIcon className="icon-style" />
                      )}
                    </div>

                    <div
                      className="m-2"
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <p className="m-0">
                        {array.title} posted by {array.title}
                      </p>
                      <p className="m-0" style={{ fontSize: "12px" }}>
                        Date:{moment(array?.createdAt).format("MMM Do YY")}
                      </p>
                    </div>
                  </div>
                  <div className="notification_p_size">
                    <p className="">{array.source}</p>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        </div>
        <div className="col-md-6 p-1">
          <div className="card border-0 shadow p-1 card_size_list">
            <div className="card-header icon_cont ">
              <AssignmentOutlinedIcon className="notification_cont ml-2 " />
              <p className="m-0 p-1">Requesting List</p>
            </div>
            {newservices.map((data, index) => (
              <ul className="list-group list-group-flush" key={index}>
                <li className="list-group-item icon_cont notification_text">
                  <div className="bgcolor">
                    <GroupIcon className="icon-style" />
                  </div>
                  <div
                    className="m-2"
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    <p className="m-0">{data.title}</p>
                    <p className="m-0" style={{ fontSize: "12px" }}>
                      Date:{moment(data?.createdAt).format("MMM Do YY")}
                    </p>
                  </div>
                </li>
              </ul>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default NotificationComponant;
