import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import Sidenav from "../SideNav/Sidenav";
import React, { useEffect, useState } from "react";
import axios from "axios";
import moment from "moment/moment";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import {
  TextField,
  IconButton,
  Button,
  Modal,
  Autocomplete,
  Divider,
  MenuItem,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { NotificationManager } from "react-notifications";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Axios from "../../axios";

const Discussions = () => {
  //------------------------Variable Decleartion------------------------------
  const [open, setOpen] =useState(false);
  const [discuss, setDiscuss] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [deleteopen, setDeleteOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [category, setCategory] = useState("");
  const [description, setDescripion] = useState("");
  const [allcategory, setAllCategory] = useState([]);
  const [updateId, setUpdateId] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(0);
  const [image, setImage] = useState("");

  //------------------------------- open function for edit Modal------------------------------
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
  };

  useEffect(() => {
    // -----------------------------to get the Discuss Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getdiscuss")
      .then((resp) => {
        setDiscuss(resp.data.discussdetails);
        setTotalPage(resp.data.page);
      })
      .catch((error) => console.log(error));
    //-----------------------------to get the category details on load------------------------------
    findDiscussionCategory();
  }, []);

  //------------------------Function to find all the category and set the Category data------------------
  const findDiscussionCategory = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getdiscusscategory")
      .then((resp) => {
        setAllCategory(resp.data.categorydetails);
      })
      .catch((error) => console.log(error));
  };
  //-------------------------click on Delete icon it reads the ID of Particular Data---------------
  const handleClickOpen = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
  };

  //-------------------------------delete function for Discussion-----------------------------
  const handleDelete = () => {
    const data = {
      _id: deleteId,
      page:page // assigning deleteid To object ID and storing it to data
    };
    Axios.post(process.env.REACT_APP_API_URL + "deletediscuss", data) //send data to backend
      .then((resp) => {
        setDiscuss(resp.data.data); //taking axios data (data 1) and deleting the ID(data 2)
        NotificationManager.success(resp.data.msg);
        setDeleteOpen(false);
      })
      .catch((error) => console.log(error));
  };
  //---------------------------Function to set catagory------------------------------
  const handleChangecategory = (event, value) => {
      setCategory(value === "" ? null : value);
  };

  //-------------------------------close function for edit option------------------------------------
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
     setTitle("");
     setCategory("");
     setDescripion("");
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };
   const imageName = image ? image.name : "";
  //--------------------------Change funtion to setStates targeting name----------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "title") {
      setTitle(value);
    } else if (name === "description") {
      setDescripion(value);
    }
  };

  //-----------------------------------function for findone Discussion using id--------------------------
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findonediscuss", data) //storing data 1 in a axios
      .then((resp) => {
        setModelTitle(resp?.data?.discussdetails.title);
        setTitle(resp?.data?.discussdetails.title);
        setCategory(resp?.data?.discussdetails?.category_ref);
        setDescripion(resp?.data?.discussdetails.content);
        setImage(resp?.data?.discussdetails.images);
      })
      .catch((error) => console.log(error));
  };

  //------------------------------Function to Submiting the data after the update----------------------------
  const handleSubmit = () => {
    const categoryRefValue = category ? category._id : null;
    const formData = new FormData();
    formData.append("id", updateId);
    formData.append("title", title);
    formData.append("category_ref", categoryRefValue);
    formData.append("page", page);
    formData.append("content", description);
    formData.append("images", image);
    Axios.post(process.env.REACT_APP_API_URL + "updatediscuss", formData) //storing data1
      .then((resp) => {
        setDiscuss(resp.data.data);
        NotificationManager.success(resp.data.msg);
        setTitle("");
        setCategory(""); 
        setDescripion("");
      })
      .catch((error) => console.log(error));
    setOpen(false);
    
  };

  const handlePageChange = (event, value) => {
    setPage(value);
    handleCard(value); //calling the pagination API user select the page--------------
  };
  //-------------------------------calling the pagination API once we select the page-------------------
  const handleCard = (page) => {
    const data = {
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "getdiscuss", data)
      .then((resp) => {
        setDiscuss(resp.data.discussdetails);
      })
      .catch((error) => console.log(error));
  };

  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 bgcolor p-1 ">
          <div className="blog_padding">
            <div className="mt-0 ml-2">
              <h1>Discussion List</h1>
            </div>
            <div className="card_flex">
              {discuss.map((data, index) => (
                <div
                  key={index}
                  className="card review_card_size m-2 border-0 shadow p-1"
                >
                  <p className="card-title mt-1 pl-2 limitedlinetitle">
                    {data.title}
                  </p>

                  {data.images && (
                    <img
                      src={data.images}
                      alt="img"
                      className="discussion_img"
                    />
                  )}
                  <div className="card-body p-2 ">
                    <div className="mt-1 card_icon_align">
                      <div className="card-icon-center ">
                        <p className="m-0 p-0 fontsize ">
                          {data?.category_ref?.name}
                        </p>
                      </div>
                      <div className="card-icon-center">
                        <CalendarTodayRoundedIcon className="icon-style" />
                        <p className="m-0 p-0 fontsize ">
                          {moment(data?.createdAt).format("MMM Do YY")}
                        </p>
                      </div>
                    </div>
                    <div className="mt-4">
                      <p className="card-text card_data mt-2 mb-3 limitedlinereview">
                        {data.content}
                      </p>
                    </div>
                    <div className=" alignicon mb-1">
                      <IconButton
                        className="bordernone"
                        aria-label="edit"
                        onClick={() => handleOpen(data._id)}
                      >
                        <EditNoteOutlinedIcon className="icon_size_below" />
                      </IconButton>

                      <IconButton
                        className="bordernone"
                        aria-label="edit"
                        onClick={() => handleClickOpen(data._id)}
                      >
                        <DeleteOutlineIcon className="icon_size_below" />
                      </IconButton>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  defaultPage={1}
                  variant="outlined"
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </div>
      </div>
      {/* --------------------Modal for delete discussion ------------------------------------ */}
      <Modal
        open={deleteopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete_modal_box pt-0 p-2">
          <div className="aligincenter">
            <p className="p-1 ml-2 m-0">Delete Discussion</p>
            <ClearIcon className="" fontSize="small" onClick={handleClose} />
          </div>
          <Divider className="mb-2"></Divider>
          <p className="ml-4 font_size_for_p">Are you sure want to delete?</p>
          <div className="p-0 deletebox">
            <Button
              variant="contained"
              className="mr-2 delete_button_size"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              className="delete_button_size"
              onClick={handleDelete}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {/* ------------------------------------Model end Here-------------------------------- */}
      <div>
        {/* --------------------Modal for edit discussion ------------------------------------ */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="review_modal_box">
            <div className="requestheader mb-4">
              <CreateNewFolderOutlinedIcon className="me-1" />
              <p className="p-0 m-0 fw-600"> {modelTitle}</p>
            </div>
            <TextField
              fullWidth
              type="text"
              className="form-control mb-4"
              value={title}
              name="title"
              onChange={handleChange}
              placeholder="Enter title"
              label="Title"
            />
            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              name="category"
              options={allcategory}
              onChange={handleChangecategory}
              getOptionLabel={(opt) =>
                opt && opt?.name !== null ? opt?.name : ""
              }
              value={
                allcategory.find((options) => options._id === category?._id) ||
                null
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />

            <TextField
              fullWidth
              name="description"
              value={description}
              onChange={handleChange}
              placeholder="Enter Description"
              label="Description"
              className="mb-4"
              multiline
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" component="label" className="mb-4">
                Upload File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                {imageName}
              </p>
            </div>
            <div className="txt-center">
              <Button
                variant="contained"
                className="mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        {/* ------------------------------------Model end Here-------------------------------- */}
      </div>
    </div>
  );
};
export default Discussions;
