import React, { useEffect, useState } from "react";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import PersonRoundedIcon from "@mui/icons-material/PersonRounded";
import CalendarTodayRoundedIcon from "@mui/icons-material/CalendarTodayRounded";
import Rating from "@mui/material/Rating";
import Sidenav from "../SideNav/Sidenav";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import {
  TextField,
  IconButton,
  Button,
  Modal,
  Divider,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import axios from "axios";
import Axios from "../../axios";
import moment from "moment/moment";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { NotificationManager } from "react-notifications";
const Review = () => {
  const [review, setReview] = useState([]);
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = useState("");
  const [reviewdata, setReviewData] = useState("");
  const [rating, setRating] = useState(0);
  const [deleteId, setDeleteId] = useState("");
  const [deleteopen, setDeleteOpen] = React.useState(false);
  const [updateId, setUpdateId] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = useState(0);
   const [image, setImage] = useState("");
  //----------------------------------open function for edit option----------------------------------
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
  };
  useEffect(() => {
    // -----------------------------to get the Review Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getreview")
      .then((resp) => {
        setReview(resp.data.reviewdetails);
        setTotalPage(resp.data.page);
      })
      .catch((error) => console.log(error));
  }, []);

  //------------------------- Click on Delete icon it reads the ID of Particular Data-----------------------------
  const handleClickOpen = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
  };
  //--------------------------Change funtion to setStates targeting name----------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "title") {
      setTitle(value);
    } else if (name === "review") {
      setReviewData(value);
     }
  };
  //--------------------------------Delete Function-----------------------------------------------------
  const handleDelete = () => {
    // 2nd data
    const data = {
      _id: deleteId,
      page: page, // assigning deleteid To object ID and storing it to data
    };
    Axios
      .post(process.env.REACT_APP_API_URL + "deletereview", data)
      .then((resp) => {
        setReview(resp.data.data); 
        NotificationManager.success(resp.data.msg);
        setDeleteOpen(false);
      })
      .catch((error) => console.log(error));
  };
  //------------------------------------function to find one review details-----------------------
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findonereview", data)
      .then((resp) => {
        setModelTitle(resp?.data?.reviewdetails.title);
        setTitle(resp?.data?.reviewdetails.title);
        setReviewData(resp?.data?.reviewdetails.description);
        setRating(resp?.data?.reviewdetails.ratings);
      })
      .catch((error) => console.log(error));
  };
  //---------------------------------------Function to close the delete model---------------------------------
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
    setTitle("");
    setReviewData("")
    setRating(null)
  };
  //---------------------------------------Function to submit the data after updating-----------------------------
  const handleSubmit = () => {
      const formData = new FormData();
      formData.append("id", updateId);
       formData.append("page", page);
      formData.append("title", title);
      formData.append("description", reviewdata);
      formData.append("ratings",rating)
      formData.append("img", image);

    Axios
      .post(process.env.REACT_APP_API_URL + "updatereview", formData)
      .then((resp) => {
        setReview(resp.data.data);
        NotificationManager.success(resp.data.msg);
        setTitle("");
        setReviewData("");
        setRating(null);
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };
  //------------------------set the page value------------------------------------
  const handlePageChange = (event, value) => {
    setPage(value);
    handleCard(value);
  };
  const handleCard = (page) => {
    const data = {
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "getreview", data)
      .then((resp) => {
        setReview(resp.data.reviewdetails);
      })
      .catch((error) => console.log(error));
  };
   const handleImageChange = (event) => {
     const selectedImage = event.target.files[0];
     setImage(selectedImage);
   };
    const imageName = image ? image.name : "";
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 bgcolor p-1">
          <div className="blog_padding">
            <div className="mt-0 ml-2">
              <h1>Review List</h1>
            </div>
            <div className="card_flex ">
              {review.map((card, index) => (
                <div
                  key={index}
                  className="card review_card_size border-0 shadow p-2 m-2 "
                >
                  <div className="card-body p-0">
                    <div style={{ display: "flex",justifyContent:"space-between" }}>
                      <h6 className="card-title limitedlinetitle ">
                        {card.title}
                      </h6>
                        <div
                          style={{ display: "flex", alignContent: "center" }}
                        >
                          <div className="rating_count mr-2 p-0">
                            {card.ratings}
                          </div>
                          <Rating
                            className="icon-style"
                            name="read-only"
                            value={card.ratings}
                            readOnly
                          />
                        </div>
                     
                    </div>
                    <div className="mt-1  card_icon_align">
                      <div className="card-icon-center mr-2">
                        <PersonRoundedIcon className="icon-style" />
                        <p className="m-0 p-0 fontsize ">
                          {card?.user_ref?.name}
                        </p>
                      </div>
                      <div className="card-icon-center">
                        <CalendarTodayRoundedIcon className="icon-style" />

                        <p className="m-0 p-0 fontsize ">
                          {moment(card?.createdAt).format("MMM Do YY")}
                        </p>
                      </div>
                    </div>
                    <div className="mb-4 mt-4 mr-2 ml-2">
                      <p className="card-text card_data limitedlinereview">
                        {card.description}
                      </p>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        {card.img && (
                          <img
                            src={card.img}
                            alt="img"
                            style={{ height: "100px", maxWidth: "100%" }}
                          />
                        )}
                      </div>
                      <div className="col-md-6 mt-5">
                        <div className="alignicon mb-0">
                          <IconButton
                            className="bordernone"
                            aria-label="edit"
                            onClick={() => handleOpen(card._id)}
                          >
                            <EditNoteOutlinedIcon className="icon_size_below" />
                          </IconButton>

                          <IconButton
                            className="bordernone"
                            aria-label="edit"
                            onClick={() => handleClickOpen(card._id)}
                          >
                            <DeleteOutlineIcon className="icon_size_below" />
                          </IconButton>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="pagination">
              <Stack spacing={2}>
                <Pagination
                  count={totalPage}
                  page={page}
                  defaultPage={1}
                  variant="outlined"
                  onChange={handlePageChange}
                  shape="rounded"
                />
              </Stack>
            </div>
          </div>
        </div>
        {/* ------------------------------------Model for delete review-------------------------------- */}
        <Modal
          open={deleteopen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="delete_modal_box pt-0 p-2">
            <div className="aligincenter">
              <p className="p-1 ml-2 m-0">Delete Review</p>
              <ClearIcon className="" fontSize="small" onClick={handleClose} />
            </div>
            <Divider className="mb-2"></Divider>
            <p className="ml-4 font_size_for_p">Are you sure want to delete?</p>
            <div className="p-0 deletebox">
              <Button
                variant="contained"
                className="mr-2 delete_button_size"
                onClick={handleClose}
              >
                No
              </Button>
              <Button
                variant="contained"
                className="delete_button_size"
                onClick={handleDelete}
              >
                Yes
              </Button>
            </div>
          </div>
        </Modal>
        {/* ------------------------------------Model end Here-------------------------------- */}
        <div>
          {/* ------------------------------------Model for edit review-------------------------------- */}
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <div className="review_modal_box ">
              <div className="requestheader mb-4">
                <CreateNewFolderOutlinedIcon className="me-1" />
                <p className="p-0 m-0 fw-600"> {modelTitle}</p>
              </div>
              <TextField
                type="text"
                className="form-control mb-4"
                value={title}
                name="title"
                onChange={handleChange}
                placeholder="Enter title"
                label="Title"
              />

              <TextField
                fullWidth
                name="review"
                value={reviewdata}
                onChange={handleChange}
                placeholder="Enter Review"
                label="Review"
                className="form-control mb-4"
                multiline
              />
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <p className="mr-4 ml-1">Rating</p>
                </div>
                <div>
                  <Rating
                    className="mb-4"
                    name="simple-controlled"
                    value={rating}
                    onChange={(event, newValue) => {
                      setRating(newValue);
                    }}
                  />
                </div>
              </div>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Button variant="contained" component="label" className="mb-4">
                  Upload File
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={handleImageChange}
                  />
                </Button>
                <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                  {imageName}
                </p>
              </div>
              <div className="txt-center">
                <Button
                  variant="contained"
                  className="mr-2"
                  onClick={handleClose}
                >
                  Cancel
                </Button>
                <Button variant="contained" onClick={handleSubmit}>
                  Submit
                </Button>
              </div>
            </div>
          </Modal>
          {/* ------------------------------------Model end Here-------------------------------- */}
        </div>
      </div>
    </div>
  );
};
export default Review;

