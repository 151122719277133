import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/revaalo2.png";
import axios from "axios";
import { Modal,Divider } from "@mui/material";
const Login = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [adminName, setAdminName] = useState("");
   const [open, setOpen] = React.useState(false);
   const [username,setUserName]=useState("")
   const [forgetpassword,setForgetPassword]=useState("")
   const [confirmpassword,setConfirmPassword]=useState("")
   const [note,setNote]=useState("");
  const navigate = useNavigate();

    const isPasswordValid = () => {
      const minPasswordLength = 8;
      if (forgetpassword.length < minPasswordLength) {
        return false;
      }

      if (forgetpassword !== confirmpassword) {
        return false;
      }

      return true;
    };
  const handleSubmit = (user,password) => {
    const data = {
      username: user,
      password: password,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "adminLogin", data)
      .then((resp) => {
        
        setAdminName('admin@revaalo');
       
        localStorage.setItem("userName", 'admin@revaalo');
        localStorage.setItem("token", resp.data.token);
        navigate("/Dashboard");
         NotificationManager.success(resp.data.msg);
        window.location.reload();
      })
      .catch((error) => NotificationManager.error(error.response.data.msg));
  };
  const handleForgetPassword = (
    username,
    forgetpassword,
    confirmpassword
  ) => {
 if (!isPasswordValid()) {
   setNote(
     "*Password should be at least 8 characters long and should match the Confirm Password."
   );
    
  
   setForgetPassword("");
   setConfirmPassword("");
   return;
 }
    const data={
      username:username,
      password:forgetpassword

    };
   
        axios
      .post(process.env.REACT_APP_API_URL + "resetpassword", data)
      .then((resp) => {
        
        setAdminName(resp.data.data.name);
         NotificationManager.success(resp.data.msg);
      })
    
      .catch((error) => NotificationManager.error(error.response.data.msg));
        setOpen(false);
    
  };

    const handleOpen = () => {
      setOpen(true);
    };
     const handleClose = () => {
       setOpen(false);
     };

  return (
    <div className="main">
      <div className="form center" action="">
        <img src={logo} className="login_logo" alt="logo"></img>
        <h2>Admin Login</h2>
        <div className="username mb-4">
          <TextField
            id="UserName"
            value={user}
            onChange={(e) => setUser(e.target.value)}
            label="User Name"
            name="Username"
          />
        </div>
        {/* <p className="p"></p> */}
        <div className="password">
          <TextField
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            label="Password"
            type="password"
            id="password"
          />
          <br />
        </div>
        <div>
          <Button
            className="button-login"
            type="submit"
            onClick={() => handleSubmit(user, password)}
          >
            Login
          </Button>
        </div>
        <div className="mt-4">
          <Button className="bordernone" type="submit" onClick={handleOpen}>
            Forget Password
          </Button>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="forgetpassword_modal_box">
          <p className="ml-4 font_size_for_p">Forget Password</p>
          <Divider className="mb-2"></Divider>
          <TextField
            type="text"
            className="form-control mb-4"
            name="user"
            value={username}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter User Name"
            label="User Name"
          />

          <TextField
            fullWidth
            name="password"
            placeholder="Enter password"
            label="Password"
            type="password"
            value={forgetpassword}
            onChange={(e) => setForgetPassword(e.target.value)}
            className="form-control mb-4"
          />
          <TextField
            fullWidth
            name="confirmpassword"
            placeholder="Enter confirm password"
            label="Confirm Password"
            value={confirmpassword}
            type="password"
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-control mb-4"
          />
          {/* <p className="note_size">{note}</p> */}
          <div className="txt-center">
            <Button variant="contained" className="mr-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() =>
                handleForgetPassword(username, forgetpassword, confirmpassword)
              }
            >
              Submit
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default Login;
