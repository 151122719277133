import React from 'react'
import PieChart from '../Charts/Piechart';
import LineChart from '../Charts/Linechart';
const Chartcomponant = () => {
  return (
   <div className='container-fluid  mt-3 '>
    <div className='row'>
          <div className='col-md-8 p-1'>
          <div className="card border-0 linechart shadow p-1 ">
            <div className="card-body">
              <h5 className="card-title">Monthly Performance</h5>
                <LineChart/>
            </div>      
          </div>
          </div>
          <div className='col-md-4 p-1'>
             <div className="card border-0 shadow p-1 paichart">
                <div className="card-body">
                  <h5 className="card-title">Campaign Performance</h5>
                   <PieChart/>
                </div>
              </div>
          </div>
        </div>
      </div>
       
       
  )
}

export default Chartcomponant