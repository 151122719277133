import React from "react";
import { Navigate } from "react-router-dom";

// import { Children } from "react";

const PrivateRoute = ({children})=>{

 const username=localStorage.getItem("userName")
  return username!=="" && username!==undefined && username!==null ? children :<Navigate to="/" />;
};
export default PrivateRoute;