import React from "react";
import Chart from "chart.js/auto";
import { Line } from "react-chartjs-2";

const labels = ["January", "February", "March", "April", "May", "June","july","Aug","sept","Oct","Nov","Dec"];

const data = {
  labels: labels,
  datasets: [
    {
      label: "Service",
      backgroundColor: "rgb(194, 230, 218)",
      borderColor: "rgb(194, 230, 218)",
      data: [0, 10, 5, 2, 20, 30, 45, 78, 90, 80, 70, 20],
    },
    {
      label: "Product",
      data: [5, 15, 25, 35, 45, 55, 87, 56, 39, 20, 90, 100],
      backgroundColor: "rgb(255, 99, 132)",
      borderColor: "rgb(255, 99, 132)",
    },
    {
      label: "User",
      data: [10, 27, 50, 40, 50, 20, 78, 90, 12, 45, 67, 89, 90],
      borderColor: "rgb(240, 187, 178)",
      backgroundColor: "rgb(240, 187, 178)",
    },
  ],
};

const LineChart = () => {
  return (
    <div>
      <Line data={data} />
    </div>
  );
};

export default LineChart;