import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import Sidenav from '../SideNav/Sidenav';
import User from './Usercardcomponant';
import Chart from './Chartcomponant';
import Notification from './Notificationcomponant';
import axios from 'axios';
const Dashboard = () => { 
  const navigate = useNavigate();
  
  return (  
    <div className='container-fluid  '>  
    <div className='row '>
        <div className='col-md-2 p-0 ' >
          <Sidenav />
        </div>  
    <div className="col-md-10  bgcolor ">
              <User/>
              <Chart/>
              <Notification/>
         </div>               
         </div>
         </div>
       
      
      );    
};
 export default Dashboard;