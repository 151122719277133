import React from 'react'
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import PersonIcon from '@mui/icons-material/Person';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ViewInArTwoToneIcon from '@mui/icons-material/ViewInArTwoTone';
import { Link } from 'react-router-dom';
const Sidenav = () => {
  const location=window.location.pathname;
  return (
    <div className='side_nav '>
         <Link to="/dashboard" className={location ==='/dashboard'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0 '}>
         <DashboardOutlinedIcon className='icon_size'  />
         <p className='m-0 p-1'> Dashboard</p></Link>
   
        <Link to="/request" className={location ==='/request'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'} >
        <FactCheckOutlinedIcon className='icon_size' />
        <p className='m-0 p-1 '>Request List</p></Link>

        <Link to="/productlist" className={location ==='/productlist'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'} >
        <ViewInArTwoToneIcon className='icon_size' />
        <p className='m-0 p-1 '>Product List</p></Link>

        <Link to="/servicelist" className={location ==='/servicelist'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'} >
        <SettingsOutlinedIcon className='icon_size' />
        <p className='m-0 p-1 '>Service List</p></Link>
   
        <Link to="/user" className={location ==='/user'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'}> <PersonIcon className='icon_size'  />
        <p className='m-0 p-1'>Users</p></Link>

        <Link to="/review" className={location ==='/review'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0 '}><StarBorderIcon className='icon_size' />                  
        <p className='m-0 p-1'>Reviwes</p></Link>

        <Link to="/blog" className={location ==='/blog'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'}><AssignmentOutlinedIcon className='icon_size'  />
         <p className='m-0 p-1'>Blogs</p></Link>

        <Link to="/discussions" className={location ==='/discussions'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'}> <ChatOutlinedIcon className='icon_size' />
        <p className='m-0 p-1'>Discussion</p></Link>

        <Link to="/request-edit" className={location ==='/request-edit'? 'active dashboard textcolor m-0':'normal dashboard textcolor m-0'}><SettingsOutlinedIcon className='icon_size'/>
        <p className='m-0 p-1 '>Setting</p></Link>
        </div>
  )
}
export default Sidenav;