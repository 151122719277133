import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem(
  "token"
)}`;
axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    // const navigate = useNavigate();
    // if (error.response && error.response.data.body === "Token is Expired") {
localStorage.removeItem()
    localStorage.clear();
    delete axios.defaults.headers.common.Authorization;
    // navigate.push("/");
    //window.location.reload();
    // }
    return Promise.reject(
      (error.response && error.response.data) || "Something went wrong!"
    );
  }
);

export default axiosInstance;
