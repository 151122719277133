import React from 'react'
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import img from '../../assets/revaalo2.png'
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Logout from '@mui/icons-material/Logout';
import MailIcon from '@mui/icons-material/Mail';
// import { useNavigate } from 'react-router-dom';
const Header = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    // const navigate=useNavigate();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    }; 
    const handleClose = () => {
      setAnchorEl(null);
      localStorage.clear();
      window.location.reload();
    };
  return (
    <div className="container-fluid header_container header_shadow ">
      <div className="row ">
        <div className="col-md-10 col-8 p-0">
          {/* <div className='header '> */}
          <div className="sub_header">
            <img src={img} alt="logo" className="header_img_size" />
            <h2 className="m-0 p-1">Revaalo</h2>
          </div>
          {/* </div> */}
        </div>

        <div className="col-md-2 col-4 m-0 d-flex align-items-center justify-content-end">
          {/* <div className='header'> */}
          <div className="sub_header">
            <IconButton className="p-0 m-2 bordernone">
              <NotificationsOutlinedIcon fontSize="medium" />
            </IconButton>
            <IconButton className="p-0 m-2 bordernone">
              <MailIcon color="action" fontSize="medium" />
            </IconButton>
            <React.Fragment>
              <Tooltip title="Account settings">
                <IconButton
                  className="p-0 m-2 bordernone"
                  onClick={handleClick}
                  size="small"
                  sx={{ ml: 1 }}
                  aria-controls={open ? "account-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                >
                  <Avatar fontSize="medium">M</Avatar>
                </IconButton>
              </Tooltip>

              <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                  elevation: 0,
                  sx: {
                    overflow: "visible",
                    filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                    mt: 1.5,
                    "& .MuiAvatar-root": {
                      width: 32,
                      height: 32,
                      ml: -0.5,
                      mr: 1,
                    },
                    "&:before": {
                      content: '""',
                      display: "block",
                      position: "absolute",
                      top: 0,
                      right: 14,
                      width: 10,
                      height: 10,
                      bgcolor: "background.paper",
                      transform: "translateY(-50%) rotate(45deg)",
                      zIndex: 0,
                    },
                  },
                }}
                transformOrigin={{ horizontal: "right", vertical: "top" }}
                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
              >
                <MenuItem>
                  <Avatar fontSize="small" /> Profile
                </MenuItem>
                <MenuItem onClick={handleClose}>
                  <ListItemIcon>
                    <Logout fontSize="small" />
                  </ListItemIcon>
                  Logout
                </MenuItem>
              </Menu>
            </React.Fragment>
          </div>
          {/* </div>   */}
        </div>
      </div>
    </div>
  );
}

export default Header