import React, { useEffect, useState } from "react";
import Sidenav from "../SideNav/Sidenav";
import MUIDataTable from "mui-datatables";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import {
  TextField,
  MenuItem,
  Select,
  Modal,
  Button,
  IconButton,
  FormControl,
  InputLabel,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { NotificationManager } from "react-notifications";
import Axios from "../../axios";
const ProductList = () => {
  //------------------------Variable Decleartion------------------------------
  const [product, setProduct] = useState([]);
  const [allcategory, setAllCategory] = useState([]);
  const [productname, setProductName] = useState("");
  const [url, setUrl] = useState("");
  const [status, setStatus] = useState("");
  const [category, setCategory] = useState("");
  const [remarks, setRemarks] = useState("");
  const [open, setOpen] =useState(false);
  const [updateId, setUpdateId] = useState("");
const [modelTitle, setModelTitle] = useState("");
 const [page, setPage] = useState(0);
 const [count, setCount] = useState();
  const [image, setImage] = useState("");
  //----------------------------- open function for edit Modal------------------------------
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
  };
//--------------------------------Close Finction for Edit Model-----------------------------------
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(() => {
    // -----------------------------to get the product Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getproduct")
      .then((resp) => {
        setProduct(resp.data.productdata);
        setCount(resp.data.numberofRecords);
      })
      .catch((error) => console.log(error));

    //-------------------------to get the category details on load------------------------
    findCategory();
  }, []);
  //---------------------------to get the category details------------------------
  const findCategory = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getallcategory")
      .then((resp) => {
        setAllCategory(resp.data.categorydetails);
      })
      .catch((error) => console.log(error));
  };
  //------------------------------function to Set category-----------------------------
  const handleChangecategory = (event, value) => {
    setCategory(value === "" ? null : value);
  };

  //-----------------------function for findone product using id for edit-----------------------------
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findoneproduct", data) //storing data 1 in a axios
      .then((resp) => {
        setModelTitle(resp?.data?.productdetails.title);
        setProductName(resp?.data?.productdetails.title);
        setUrl(resp?.data?.productdetails.website);
        setStatus(resp?.data?.productdetails.status);
          if (resp?.data?.productdetails?.category_ref) {
            setCategory(resp?.data?.productdetails?.category_ref);
          } else {
            setCategory("");
          }
        setRemarks(resp?.data?.productdetails.overview);
      })
      .catch((error) => console.log(error));
  };

  //--------------------------Change funtion to setStates targeting name----------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "product") {
      setProductName(value);
    } else if (name === "url") {
      setUrl(value);
    } else if (name === "category") {
      setCategory(value);
    } else if (name === "status") {
      setStatus(value);
    } else if (name === "remarks") {
      setRemarks(value);
    }
  };
  //---------------------function to update the product once onclick on submit button--------------------
  const handleSubmit = () => {
     const categoryRefValue = category ? category._id : null;
    // const data = {
    //   id: updateId,
    //   title: productname,
    //   website: url,
    //   category_ref: categoryRefValue,
    //   status: status,
    //   overview: remarks,
    // };
    const formData = new FormData();
    formData.append("id", updateId);
    formData.append("title", productname);
    formData.append("website", url);
     formData.append("category_ref", categoryRefValue);
     formData.append("status", status);
      formData.append("overview", remarks);
     formData.append("images", image);
    //------------------------Api to submit the data after editing--------------------
    Axios.post(process.env.REACT_APP_API_URL + "updateproduct", formData) //storing data1
      .then((resp) => {
        setProduct(resp.data.data);
        NotificationManager.success(resp.data.msg);
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };
  //-------------- Columns for product Table Start here *product is the array for the data-------------

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };
  const imageName = image ? image.name : "";

  const columns = [
    {
      name: "title",
      label: "Service Name",
    },
    {
      name: "Category",
      label: "Category",
      options: {
        customBodyRenderLite: (dataIndex) => {
    const categoryRef = product[dataIndex].category_ref;
    return <div>{categoryRef ? categoryRef.Category : ""}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              {product[dataIndex].status !== "Active" ? (
                <p className="mt-3" style={{ color: "red" }}>
                  Inactive
                </p>
              ) : (
                <p className="mt-3" style={{ color: "green" }}>
                  Active
                </p>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="usericon">
              <IconButton
                className="bordernone"
                onClick={() => handleOpen(product[dataIndex]._id)} //calling the handleOpen function to open the edit option
                aria-label="edit"
              >
                <EditNoteOutlinedIcon size="small" />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  // ------------------------Columns End here---------------------------------------------
 const handlePageChange = (page) => {
   const data = {
     page: page,
   };
   axios
     .post(process.env.REACT_APP_API_URL + "getproduct", data)
     .then((resp) => {
       setProduct(resp.data.productdata);
       setCount(resp.data.numberofRecords);
       setPage(page);
     })
     .catch((error) => console.log(error));
 };

 const options = {
   responsive: "standard",
   selectableRows:"none",
   filters: false,
   rowsPerPage: 10,
   serverSide: true,
   //count, // Use total number of items
   count: count, // Unknown number of items
   page,
   onTableChange: (action, tableState) => {
     if (action === "changePage") {
       console.log("to page", tableState.page);
       handlePageChange(tableState.page);
     }
   },
 };
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 container-fluid bgcolor">
          <div className="request_padding">
            <MUIDataTable
              className=""
              title={"Product List"}
              data={product}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
      <div>
        {/* --------------------Modal for edit product Details------------------------------- */}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal_box ">
            <div className="requestheader mb-4 mt-2">
              <CreateNewFolderOutlinedIcon className="me-1" />
              <p className="p-0 m-0  fw-600">{modelTitle}</p>
            </div>
            <TextField
              type="text"
              className="form-control mb-4"
              value={productname}
              name="product"
              onChange={handleChange}
              placeholder="Enter the Service Name"
              label="Product/Service Name*"
            />

            <TextField
              type="text"
              value={url}
              name="url"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the website URL"
              label="Website URL"
            />
            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              name="category"
              options={allcategory}
              onChange={handleChangecategory}
              getOptionLabel={(opt) =>
                opt && opt?.Category !== null ? opt?.Category : ""
              }
              value={
                allcategory.find((options) => options._id === category?._id) ||
                null
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                fullWidth
                style={{ height: "60px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                name="status"
                label="status"
                placeholder="Enter the status"
                onChange={handleChange}
                className="form-control mb-4"
              >
                <MenuItem value="Active">Active</MenuItem>
                <MenuItem value="Inactive">Inactive</MenuItem>
              </Select>
            </FormControl>
            <TextField
              type="text"
              value={remarks}
              name="remarks"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter Remarks"
              label="Remarks"
            />
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" component="label" className="mb-4">
                Upload File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                {imageName}
              </p>
            </div>
            <div className="txt-center">
              <Button
                variant="contained"
                className="mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
        {/* --------------------Modal end here------------------------------- */}
      </div>
    </div>
  );
};

export default ProductList;
