import React, { useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import Axios from "../../axios";
import ClearIcon from "@mui/icons-material/Clear";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import {
  TextField,
  MenuItem,
  Select,
  IconButton,
  Button,
  Modal,
  InputLabel,
  FormControl,
  Divider,
  Autocomplete
} from "@mui/material";
import { NotificationManager } from "react-notifications";
const PendingRequest = () => {
  const [pendingservices, setPendingServices] = useState([]);
  const [allcategory, setAllCategory] = useState([]);
  const [allcity, setAllCity] = useState([]);
  const [open, setOpen] = useState(false);
  const [deleteopen, setDeleteOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [servicename, setServiceName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [address, setAddress] = useState("");
  const [url, setUrl] = useState("");
  const [overview, setOverview] = useState("");
  const [status, setStatus] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [image,setImage]=useState("")
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
  };
  useEffect(() => {
    // -----------------------------to get the User Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getnewservice")
      .then((resp) => {
        const filteredData = resp.data.newservicesdata.filter(
          (item) => item.condition === "Pending"
        );
        setPendingServices(filteredData);
        setCount(resp.data.numberofRecords);
        findArea();
        findCategory();
      })
      .catch((error) => console.log(error));
  }, []);

  const handleClickOpen = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
    handlefindone(id);
  };
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
  };
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findnewservices", data) //storing data 1 in a axios
      .then((resp) => {
        setServiceName(resp?.data?.newServicedetails.title);
        setEmail(resp?.data?.newServicedetails.email);
        setPhone(resp?.data?.newServicedetails.phone_number);
        setArea(resp?.data?.newServicedetails.areaName);
        if (resp?.data?.newServicedetails?.area_ref) {
          setCity(resp?.data?.newServicedetails?.area_ref);
         } else {
        setCity(null);
        }
        setAddress(resp?.data?.newServicedetails.address);
        setUrl(resp?.data?.newServicedetails.website);
        setCategory(resp?.data?.newServicedetails.category_ref);
        setOverview(resp?.data?.newServicedetails.overview);
        setStatus(resp?.data?.newServicedetails.condition);
        setModelTitle(resp?.data?.newServicedetails.title);
      })
      .catch((error) => console.log(error));
  };

  //----------------------------delete function for user----------------------------------------
  const handleDelete = () => {
    const data = {
      id: deleteId, // assigning deleteid To object ID and storing it to data
    };
    Axios.post(process.env.REACT_APP_API_URL + "deletenewservice", data) //storing data 1 in a axios
      .then((resp) => {
        setPendingServices(resp.data.data); //taking axios data (data 1) and deleting the ID(data 2)
        NotificationManager.success(resp.data.msg);
        setDeleteOpen(false); //to close the delete window
      })
      .catch((error) => console.log(error));
  };
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "service") {
      setServiceName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "overview") {
      setOverview(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "url") {
      setUrl(value);
    } else if (name === "status") {
      setStatus(value);
    }
  };
  //-------------------------------------Function to fetch all the area on load----------------
  const findArea = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getallservicesarea")
      .then((resp) => {
        setAllCity(resp.data.areadetails);
      })
      .catch((error) => console.log(error));
  };
  //-------------------------------Function to fetch all category on load-----------------------------------
  const findCategory = () => {
    axios
      .get(process.env.REACT_APP_API_URL + "getallcategory")
      .then((resp) => {
        setAllCategory(resp.data.categorydetails);
      })
      .catch((error) => console.log(error));
  };

   const handleChangecategory = (event, value) => {
      setCategory(value === "" ? null : value);
   };
  const handleSubmit = () => {
  const categoryRefValue = category ? category._id : null;
  const cityRefValue = city ? city._id : null;
      const formData = new FormData();
      formData.append("id", updateId);
      formData.append("title", servicename);
      formData.append("mail", email);
      formData.append("phone_number", phone);
      formData.append("area_ref", cityRefValue);
      formData.append("address", address);
      formData.append("website", url);
      formData.append("category_ref", categoryRefValue);
      formData.append("overview", overview);
      formData.append("condition", status);
      formData.append("images", image);
    Axios.post(process.env.REACT_APP_API_URL + "updatenewservice", formData) //storing data1
      .then((resp) => {
        const filteredData = resp.data.newservicesdata.filter(
          (item) => item.condition === "Pending"
        );
        setPendingServices(filteredData);
        NotificationManager.success(resp.data.msg);
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };

  const handleImageChange = (event) => {
    const selectedImage = event.target.files[0];
    setImage(selectedImage);
  };
  const imageName = image ? image.name : "";

  const columns = [
    {
      name: "title",
      label: "Service Name",
    },
    {
      name: "phone_number",
      label: "Mobile No",
    },
    {
      name: "overview",
      label: "Overview",
    },
    {
      name: "address",
      label: "City",
      options: {
        customBodyRenderLite: (dataIndex) => {
          const cityRef = pendingservices[dataIndex].area_ref;
          return <div>{cityRef ? cityRef.city_name : ""}</div>;
        },
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div>
              <p
                className="statuscolor mt-3"
                style={{
                  color:
                    pendingservices[dataIndex].condition === "Pending"
                      ? "yellow"
                      : pendingservices[dataIndex].condition === "Rejected"
                      ? "red"
                      : "green",
                }}
              >
                {pendingservices[dataIndex].condition}
              </p>
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="usericon">
              {/* <IconButton className="bordernone" aria-label="edit">
              <WarningIcon className="p-0.5" color="warning" />
            </IconButton> */}
              <IconButton
                className="bordernone p-1"
                aria-label="edit"
                onClick={() => {
                  handleOpen(pendingservices[dataIndex]._id); //calling the handleOpen function to open the edit option
                }}
              >
                <EditNoteOutlinedIcon />
              </IconButton>
              <IconButton
                className="bordernone p-1"
                aria-label="delete"
                onClick={() => {
                  handleClickOpen(pendingservices[dataIndex]._id); //calling the handleOpen function to open the edit option
                }}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  const handlePageChange = (page) => {
    const data = {
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "getnewservice", data)
      .then((resp) => {
        setPendingServices(resp.data.data);
        setCount(resp.data.numberofRecords);
        setPage(page);
      })
      .catch((error) => console.log(error));
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    filters: false,
    rowsPerPage: 10,
    serverSide: true,
    //count, // Use total number of items
    count: count, // Unknown number of items
    page,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        // console.log("to page", tableState.page);
        handlePageChange(tableState.page);
      }
    },
  };

  return (
    <div className="container-fluid ">
      <MUIDataTable
        title={"Pending Request"}
        data={pendingservices}
        columns={columns}
        options={options}
      />
      <Modal
        open={deleteopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete_modal_box pt-0 p-2">
          <div className="aligincenter">
            <p className="p-1 ml-2 m-0">Delete Request</p>
            <ClearIcon className="" fontSize="small" onClick={handleClose} />
          </div>
          <Divider className="mb-2"></Divider>
          <p className="ml-4 font_size_for_p">Are you sure want to delete?</p>
          <div className="p-0 deletebox">
            <Button
              variant="contained"
              className="mr-2 delete_button_size"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              className="delete_button_size"
              onClick={handleDelete}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <div className="modal_box ">
            <div className="requestheader mb-4">
              <CreateNewFolderOutlinedIcon className="me-1" />
              <p className="p-0 m-0 fw-600"> {modelTitle}</p>
            </div>
            <TextField
              type="text"
              className="form-control mb-4"
              value={servicename}
              name="service"
              onChange={handleChange}
              placeholder="Enter the Service Name"
              label="Product/Service Name*"
            />
            <TextField
              type="text"
              className="form-control mb-4"
              value={email}
              name="email"
              onChange={handleChange}
              placeholder="Enter the  Email"
              label="Email"
            />
            <TextField
              type="text"
              value={phone}
              name="phone"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the phone number"
              label="Phone Number"
            />

            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              options={allcity}
              value={city ? city : null}
              onChange={(event, value) => {
                setCity(value);
              }}
              getOptionLabel={(allcity) =>
                allcity ? `${allcity.areaName}, ${allcity.city_name}` : ""
              }
              renderInput={(params) => <TextField {...params} label="City" />}
            />

            <TextField
              fullWidth
              name="address"
              value={address}
              onChange={handleChange}
              placeholder="Enter address"
              label="Address"
              className="mb-4"
              multiline
            />
            <TextField
              type="text"
              value={url}
              name="url"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the website URL"
              label="Website URL"
            />
            <TextField
              type="text"
              value={overview || ""}
              name="overview"
              className="form-control mb-4"
              onChange={handleChange}
              placeholder="Enter the overview"
              label="Overview"
            />

            <Autocomplete
              fullWidth
              freeSolo
              id="combo-box-demo"
              className=" mb-4"
              name="category"
              options={allcategory}
              onChange={handleChangecategory}
              getOptionLabel={(opt) =>
                opt && opt?.Category !== null ? opt?.Category : ""
              }
              value={
                allcategory.find((options) => options._id === category?._id) ||
                null
              }
              renderInput={(params) => (
                <TextField {...params} label="Category" />
              )}
            />
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                fullWidth
                style={{ height: "60px" }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={status}
                name="status"
                label="Status"
                placeholder="Select the Status"
                onChange={handleChange}
                className="form-control mb-4"
              >
                <MenuItem value="Pending"> Pending</MenuItem>
                <MenuItem value="Rejected">Rejected</MenuItem>
                <MenuItem value="Approved">Approved</MenuItem>
              </Select>
            </FormControl>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button variant="contained" component="label" className="mb-4">
                Upload File
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleImageChange}
                />
              </Button>
              <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
                {imageName}
              </p>
            </div>

            <div className="txt-center">
              <Button
                variant="contained"
                className="mr-2"
                onClick={handleClose}
              >
                Cancel
              </Button>
              <Button variant="contained" onClick={handleSubmit}>
                Submit
              </Button>
            </div>
          </div>
        </Modal>
      </div>
    </div>
  );
};
export default PendingRequest;
