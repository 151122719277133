import React, { useEffect, useState } from "react";
import Sidenav from "../SideNav/Sidenav";
import MUIDataTable from "mui-datatables";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNote";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  TextField,
  MenuItem,
  Select,
  Switch,
  IconButton,
  Button,
  Modal,
  InputLabel,
  FormControl,
  Divider,
} from "@mui/material";
import axios from "axios";
import Axios from "../../axios";
import ClearIcon from "@mui/icons-material/Clear";
import CreateNewFolderOutlinedIcon from "@mui/icons-material/CreateNewFolderOutlined";
import { NotificationManager } from "react-notifications";

const User = () => {
  //------------------------Variable Decleartion------------------------------
  const [user, setUser] = useState([]);
  const [username, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [gender, setGender] = useState("");
  const [phone, setPhone] = useState("");
  const [date, setDate] = useState("");
  const [address, setAddress] = useState("");
  const [open, setOpen] = useState(false);
  const [deleteopen, setDeleteOpen] = useState(false);
  const [updateId, setUpdateId] = useState("");
  const [deleteId, setDeleteId] = useState("");
  const [modelTitle, setModelTitle] = useState("");
  const [page, setPage] = useState(0);
  const [count, setCount] = useState();
  const [image, setImage] = useState("");
  //----------------------------------open function for edit option-------------------------
  const handleOpen = (id) => {
    setOpen(true);
    setUpdateId(id);
    handlefindone(id);
  };

  useEffect(() => {
    // -----------------------------to get the User Details on load-----------------------------
    axios
      .post(process.env.REACT_APP_API_URL + "getusers")
      .then((resp) => {
        setUser(resp.data.usersdata);
        setCount(resp.data.numberofRecords);
      })
      .catch((error) => console.log(error));
  }, []);

  //-----------------------------click on Delete icon it reads the ID of Particular Data---------------
  const handleClickOpen = (id) => {
    setDeleteOpen(true);
    setDeleteId(id);
    handlefindone(id);
  };
  //------------------------------close function for edit option-------------------------------
  const handleClose = () => {
    setOpen(false);
    setDeleteOpen(false);
     setUserName("");
     setEmail("");
     setPhone("");
     setAddress("");
     setDate("");
     setGender("");
  };

  //----------------------------delete function for user----------------------------------------
  const handleDelete = () => {
    const data = {
      id: deleteId, // assigning deleteid To object ID and storing it to data
    };
    Axios.post(process.env.REACT_APP_API_URL + "deleteusers", data) //storing data 1 in a axios
      .then((resp) => {
        setUser(resp.data.data); //taking axios data (data 1) and deleting the ID(data 2)
        NotificationManager.success(resp.data.msg);
        setDeleteOpen(false); //to close the delete window
      })
      .catch((error) => console.log(error));
  };

  //-------------------------------function for findone user using id--------------------------------
  const handlefindone = (id) => {
    const data = {
      id: id,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "findoneusers", data) //storing data 1 in a axios
      .then((resp) => {
        setModelTitle(resp?.data?.userdetails.name);
        setUserName(resp?.data?.userdetails.name);
        setEmail(resp?.data?.userdetails.mail);
        setGender(resp?.data?.userdetails.gender);
        setAddress(resp?.data?.userdetails.address);
        setDate(resp?.data?.userdetails.dob);
        setPhone(resp?.data?.userdetails.phone);
      })
      .catch((error) => console.log(error));
  };
  //--------------------------Change funtion to setStates targeting name----------------------------
  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    if (name === "username") {
      setUserName(value);
    } else if (name === "email") {
      setEmail(value);
    } else if (name === "phone") {
      setPhone(value);
    } else if (name === "address") {
      setAddress(value);
    } else if (name === "gender") {
      setGender(value);
    } else if (name === "date") {
      setDate(value);
    }
  };
  //---------------------------Function to Submiting the data after the update-----------------------------
  const handleSubmit = () => {
    const formData = new FormData(); 
       formData.append("id", updateId);
       formData.append("name", username);
       formData.append("mail", email);
       formData.append("phone", phone);
       formData.append("dob", date);
       formData.append("address", address);
       formData.append("gender",gender)
       formData.append("picture", image);
    Axios.post(process.env.REACT_APP_API_URL + "updateuser", formData) //storing data1
      .then((resp) => {
        setUser(resp.data.data);
        NotificationManager.success(resp.data.msg);
        setUserName("");
        setEmail("");
        setPhone("");
        setAddress("");
        setDate("");
        setGender("");
      })
      .catch((error) => console.log(error));
    setOpen(false);
  };

  const handleChecked = (id) => {
    const userToUpdate = user.find((u) => u._id === id);
    if (!userToUpdate) {
      return;
    }
    const updatedUser = { ...userToUpdate, verified: !userToUpdate.verified };
    axios
      .post(process.env.REACT_APP_API_URL + "verifyuser", {
        id: id,
        verified: updatedUser.verified,
      })
      .then((resp) => {
        setUser(resp.data.data);
      })
      .catch((error) => {
        console.error("API error:", error);
      });
  };
    const handleImageChange = (event) => {
      const selectedImage = event.target.files[0];
      setImage(selectedImage);
    };
     const imageName = image ? image.name : "";
  //-------------- Columns for User Table Start here *user is the array for the data-------------
  const columns = [
    {
      name: "name",
      label: "User Name",
    },
    {
      name: "phone",
      label: "Mobile No",
    },
    {
      name: "mail",
      label: "Mail-ID",
    },
    {
      name: "verified",
      label: "Status",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div style={{ alignItems: "center", textAlign: "center" }}>
              {user[dataIndex].verified !== true ? (
                <p style={{ color: "red" }} className="mt-3">
                  
                  Not Verified
                </p>
              ) : (
                <p className="mt-3" style={{ color: "green" }}>
                  Verified
                </p>
              )}
            </div>
          );
        },
      },
    },
    {
      name: "action",
      label: "Action",
      options: {
        customBodyRenderLite: (dataIndex) => {
          return (
            <div className="usericon">
              <Switch
                size="small"
                onChange={() => {
                  handleChecked(user[dataIndex]._id);
                }}
                checked={user[dataIndex].verified}
              />
              <IconButton
                className="bordernone"
                aria-label="edit"
                onClick={() => {
                  handleOpen(user[dataIndex]._id); //calling the handleOpen function to open the edit option
                }}
              >
                <EditNoteOutlinedIcon size="small" />
              </IconButton>
              <IconButton
                className="bordernone"
                aria-label="delete"
                onClick={() => handleClickOpen(user[dataIndex]._id)} //calling the handleClickOpen function to open the delete option
              >
                <DeleteIcon className="icon_size_below" />
              </IconButton>
            </div>
          );
        },
      },
    },
  ];
  // ------------------------------------Columns End here---------------------------------------------

  const handlePageChange = (page) => {
    const data = {
      page: page,
    };
    axios
      .post(process.env.REACT_APP_API_URL + "getusers", data)
      .then((resp) => {
        setUser(resp.data.usersdata);
        setCount(resp.data.numberofRecords);
        setPage(page);
      })
      .catch((error) => console.log(error));
  };

  const options = {
    responsive: "standard",
    selectableRows: "none",
    filters: false,
    rowsPerPage: 10,
    serverSide: true,
    //count, // Use total number of items
    count: count, // Unknown number of items
    page,
    onTableChange: (action, tableState) => {
      if (action === "changePage") {
        console.log("to page", tableState.page);
        handlePageChange(tableState.page);
      }
    },
  };
  return (
    <div className="container-fluid ">
      <div className="row">
        <div className="col-md-2 p-0">
          <Sidenav />
        </div>
        <div className="col-md-10 bgcolor  ">
          <div className="request_padding">
            <MUIDataTable
              title={"User List"}
              data={user}
              columns={columns}
              options={options}
            />
          </div>
        </div>
      </div>
      {/* --------------------Modal for delete user ------------------------------------ */}
      <Modal
        open={deleteopen}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="delete_modal_box pt-0 p-2">
          <div className="aligincenter">
            <p className="p-1 ml-2 m-0">Delete User</p>
            <ClearIcon className="" fontSize="small" onClick={handleClose} />
          </div>
          <Divider className="mb-2"></Divider>
          <p className="ml-4 font_size_for_p">Are you sure want to delete?</p>
          <div className="p-0 deletebox">
            <Button
              variant="contained"
              className="mr-2 delete_button_size"
              onClick={handleClose}
            >
              No
            </Button>
            <Button
              variant="contained"
              className="delete_button_size"
              onClick={handleDelete}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
      {/* ------------------------------Model for delete user-------------------------------- */}
      {/* --------------------Modal for edit user Details------------------------------- */}

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div className="modal_box ">
          <div className="requestheader mb-4">
            <CreateNewFolderOutlinedIcon className="me-1" />
            <p className="p-0 m-0 fw-600"> {modelTitle}</p>
          </div>
          <TextField
            type="text"
            className="form-control mb-4"
            value={username}
            name="username"
            onChange={handleChange}
            placeholder="Enter the User Name"
            label="User Name*"
          />

          <TextField
            type="text"
            name="email"
            value={email}
            className="form-control mb-4"
            onChange={handleChange}
            placeholder="Enter the email"
            label="User Email"
          />

          <TextField
            type="text"
            name="phone"
            className="form-control mb-4"
            onChange={handleChange}
            value={phone}
            placeholder="Enter the Phone Number"
            label="User Phone Number"
          />

          <TextField
            fullWidth
            type="date"
            name="date"
            className="mb-4"
            onChange={handleChange}
            value={date}
          />
          <FormControl fullWidth>
            <InputLabel id="demo-select-small-label">Gender</InputLabel>
            <Select
              style={{ height: "60px" }}
              fullWidth
              labelId="demo-select-small-label"
              id="demo-simple-select"
              value={gender}
              name="gender"
              label="Gender"
              placeholder="Enter the Gender"
              onChange={handleChange}
              className="form-control mb-4"
            >
              <MenuItem value="Male">Male</MenuItem>
              <MenuItem value="Female">Female</MenuItem>
              <MenuItem value="Binary">Binary</MenuItem>
            </Select>
          </FormControl>
          <TextField
            fullWidth
            name="address"
            value={address}
            onChange={handleChange}
            placeholder="Enter address"
            label="Address"
            className="mb-4"
            multiline
          />
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" component="label" className="mb-4">
              Upload File
              <input
                type="file"
                hidden
                accept="image/*"
                onChange={handleImageChange}
              />
            </Button>
            <p className="mb-4 ml-2" style={{ fontSize: "14px" }}>
              {imageName}
            </p>
          </div>
          <div className="txt-center">
            <Button variant="contained" className="mr-2" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </div>
        </div>
      </Modal>
      {/* --------------------Modal end here------------------------------- */}
    </div>
  );
};

export default User;
